@charset "utf-8";

@import 'setup';

html,
body {
  height: 100%;
}

// Header
.hero {
  background-color: $navbar-background-color;
  z-index: 100;

  position: sticky;
  top: 0;
  left: 0;

  .hero-head {
    .container {
      margin-left: 1rem;
      max-width: 100%;
    }
  }

  .navbar {
    .navbar-brand {
      margin-right: 2rem;
      margin-left: 1.5rem;

      width: 100%;
      justify-content: space-between;
    }
  }

  .navbar-item {
    font-size: 1rem;

    &.navbar-item-notification-container {
      margin-right: 2rem;
    }

    &.has-text-brand-light {
      &:hover {
        background-color: $brand-light;
        color: $grey-dark;
      }
    }

    .app-notifications-container {
      height: 1.5rem;
      .icon {
        &:hover {
          cursor: pointer;
          font-size: 1.5rem;
        }
      }
    }
  }
}

// Custom styling
.notification {
  background-color: $white-dark;
}

// Color modifiers
.has-text-brand-light {
  color: $brand-light;
}

.has-text-brand-dark {
  color: $brand-dark;
}

.has-text-grey-light {
  color: $grey-light;
}

.has-text-grey-dark {
  color: $grey-dark;
}

.has-text-white-light {
  color: $white-light;
}

.has-text-white-dark {
  color: $white-dark;
}

.is-brand-light {
  background-color: $brand-light;
}

.is-brand-dark {
  background-color: $brand-dark;
}

.is-grey-light {
  background-color: $grey-light;
}

.is-grey-dark {
  background-color: $grey-dark;
}

.is-white-light {
  background-color: $white-light;
}

.is-white-dark {
  background-color: $white-dark;
}

.is-underlined {
  text-decoration: underline;
}

.button {
  &.is-brand {
    background-color: $brand-light;
    border-color: $brand-light;
  }
  &.is-brand-dark {
    background-color: $brand-dark;
    border-color: $brand-dark;
  }
}

.selectable {
  user-select: text;
}

.interactable {
  -webkit-app-region: no-drag;
}

.fa-5x {
  font-size: 5em;
}

.icon {
  &.is-huge {
    height: 7em;
    width: 7em;
  }
  &.is-big {
    height: 5em;
    width: 5em;
  }
}

.is-05 {
  width: 5% !important;
}

.is-06 {
  width: 6% !important;
}

.fa-huge {
  font-size: 3.33em;
}

.fa-big {
  font-size: 2rem;
}

.is-centered-by-margin {
  margin: 0 auto;
}

// .vertically-centered {
//   &.table-cell {
//     vertical-align: middle;
//   }

//   &.centered-in-the-container {
//     padding-top: 0 !important;
//     display: flex;
//     flex-direction: column;
//     margin-top: auto;
//     margin-bottom: auto;
//     &.not-growing {
//       flex-grow: 0;
//     }
//   }
// }

.element {
  &.is-loading {
    position: relative;
    pointer-events: none;
    opacity: 0.5;
    &:after {
      @include loader;
      position: absolute;
      top: 25%;
      left: 50%;
      width: 1.5rem;
      height: 1.5rem;
      border-width: 0.25em;
    }
  }
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 0;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.5s;
  &.mnemonic {
    font-size: 1rem;
    top: -2.2rem;
    height: 2rem;
  }
  &.account-address {
    left: 3px;
  }
}

.tooltip .tooltiptext::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.is-fullwidth {
  width: 100% !important;
}

.is-halfwidth {
  width: 50% !important;
}

.is-25-width {
  width: 25% !important;
}

.is-border-radius-less {
  border-radius: 0;
  border-width: 0;
}

.is-border-less {
  border: none;
}

.show-overflow {
  overflow: visible !important;
}
