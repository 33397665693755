// @import "../../css/utils.scss";

.loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px;
}

@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.spinner {
  animation: spin 4s linear infinite;
  filter: grayscale(40%);
  height: 80px;
  width: 80px;
}

.spinner + p {
  margin-top: 20px;
}
