@import '../../../../../styles/common.scss';

.proposal-details-main-container {
  .modal {
    &.proposal-funding-modal {
      .modal-content {
        width: 50%;
        max-width: 31rem;
      }

      p.message-title {
        margin-top: 1rem;
        margin-bottom: 1.5rem;
      }

      .field {
        .field-label {
          &.fee-field-label {
            padding-top: 0;
          }
        }

        &.is-horizontal {
          &.fee-feild {
            margin-bottom: 1rem;
          }
        }

        .control {
          &.fee-control {
            height: 100%;
            display: flex;
            align-items: center;
          }
        }

        .proposals-accounts-dropdown {
          width: 100%;

          .dropdown-trigger {
            width: 100%;

            .button {
              &.proposals-accounts-select {
                &:focus {
                  outline-color: $brand-dark;
                }
                font-size: medium;
                padding-top: 0;
                padding-bottom: 0;
                width: 100%;
              }
            }

            .account-list-dropdown-label {
              width: 100%;
            }
          }

          .accounts-dropdown-menu {
            width: 100%;
          }
        }
      }

      .buttons-container {
        margin-top: 2rem;
        .button {
          width: 50%;
          &:hover {
            &.is-danger {
              background-color: $brand-light;
              color: $grey-dark;
            }
            &.is-brand-dark {
              background-color: $brand-light;
              border-color: $brand-light;
            }
          }
        }
      }
    }
  }
}
