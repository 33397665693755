// Import a Proxima Nove Adobe Font
// @import url('https://use.typekit.net/szi8tin.css');
@import url('https://fonts.googleapis.com/css?family=Nunito+Sans:300,300i,400,400i,600,600i,700,700i,800,800i&display=swap');
// Set your brand colors
// colors from Sasha
$brand-dark: #008da0;
$brand-light: #00f2d0;
$brand-lighter: #defdf9;

$white-light: #fbfbfe;
$white-dark: #f3f3f3;

$grey-lighter: #ccc;
$grey-light: #9b9b9b;
$grey-dark: #384243;
$black: #000000;
$linked-in: #0077b5;

// Update Bulma's global variables
// $family-primary: 'proxima-nova', BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
$family-primary: 'Nunito Sans', BlinkMacSystemFont, -apple-system, 'Segoe UI',
  'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
  'Helvetica Neue', 'Helvetica', 'Arial', sans-serif;
$family-sans-serif: $family-primary;
$primary: $grey-light;

$link: $brand-dark;

$widescreen-enabled: true;
$fullhd-enabled: false;

// Update some of Bulma's component variables
$body-background-color: $white-light;
$control-border-width: 0.1rem;

// $input-border-: transparent;
$input-shadow: none;
$input-radius: 0.05rem;
$input-color: $grey-dark;
$input-background-color: $white-light;

// Navbar
$navbar-divider-background-color: $white-dark;
$navbar-gradient-color: linear-gradient($grey-dark, $grey-light);

$navbar-item-color: $white-dark;
$navbar-background-color: $grey-dark;

// For mouse hover
$navbar-dropdown-background-color: $grey-dark;
$navbar-dropdown-item-hover-color: $white-light;
$navbar-dropdown-item-hover-background-color: $grey-light;

// For mouse hover
$navbar-item-hover-color: $white-light;
$navbar-item-hover-background-color: $grey-light;

// For class is-active
$navbar-item-active-color: $brand-light;
$navbar-item-active-background-color: $brand-light;

// Menu
$menu-item-hover-color: $grey-dark;
$menu-item-hover-background-color: $brand-light;
$menu-item-active-color: $brand-lighter;
$menu-item-active-background-color: $brand-dark;

// Footer
$contact-us-color: $grey-light;
$contact-us-color-hover: $white-light;

$table-head-background-color: $grey-dark;
$table-head-cell-color: $white-dark;

$dropdown-item-hover-color: $white-light;
$dropdown-item-hover-background-color: $brand-dark;

@import '../../node_modules/bulma/bulma.sass';
