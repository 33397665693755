@import '../../../../styles/common.scss';

.proposal-details-main-container {
  padding: 1rem;
  padding-top: 0.5rem;

  .proposal-details-container {
    display: flex;
    justify-content: center;

    .proposal-form {
      width: 80%;

      .field {
        &.buttons-container-field {
          margin-top: 5rem;
        }
      }

      .field-label {
        &.proposal-details-label {
          max-width: 10rem;

          margin-top: auto;
          margin-bottom: auto;
          padding-top: 0;

          label.label {
            min-width: 5rem;
          }
        }
      }

      .field-input {
        &.full-width-input {
          width: 100% !important;
        }
        &.description-area {
          padding: 1rem;
          font-size: medium;
          border-color: rgb(204, 204, 204);
          background-color: transparent;
          border-width: 2px;
          &:focus {
            outline-color: $brand-dark;
          }
          &:hover {
            border-color: $grey-light;
          }
        }
      }

      .control {
        &.buttons-container {
          display: flex;
          justify-content: space-evenly;
        }
      }

      .button {
        &.actions-button {
          width: 15%;
          &:hover {
            &.is-danger {
              background-color: $brand-light;
              color: $grey-dark;
            }
            &.is-brand-dark {
              background-color: $brand-light;
              border-color: $brand-light;
            }
          }
        }
      }
    }
  }
}
