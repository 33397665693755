@charset "utf-8";

@import 'common';

body {
  div#root {
    height: 100%;
  }
}

.dropdown {
  button.button {
    &.dropdown-select-button {
      font-size: smaller;
      outline-style: solid;
      border-color: transparent;
      outline-color: rgb(204, 204, 204);
      outline-width: 2px;
      &:hover {
        outline-color: $grey-light;
      }
      &:focus {
        &:active {
          outline-color: $brand-light;
        }
        &:not(:active) {
          outline-color: $brand-dark;
        }
      }
    }
  }
}
