@import '../../../../styles/common.scss';

.create-proposal-main-container {
  padding: 1rem;
  padding-top: 0.5rem;

  .create-proposal-container {
    display: flex;
    justify-content: center;

    .proposal-form {
      width: 80%;

      .field {
        &.buttons-container-field {
          margin-top: 5rem;
        }

        &.is-horizontal {
          margin-bottom: 0.4rem;

          &.is-funding-goal-field {
            width: 60%;
          }
          &.is-name-field {
            width: 50%;
          }

          &.no-margin-bottom {
            margin-bottom: 0;
          }
        }
      }

      .field-label {
        &.create-proposal-label {
          max-width: 9rem;

          margin-top: auto;
          margin-bottom: auto;
          padding-top: 0;
        }
      }

      .field-input {
        &.full-width-input {
          width: 100% !important;
        }
        &.description-area {
          padding: 1rem;
          font-size: medium;
          border-color: rgb(204, 204, 204);
          background-color: transparent;
          border-width: 2px;
          &:focus {
            outline-color: $brand-dark;
          }
          &:hover {
            border-color: $grey-light;
          }
        }
      }

      .control {
        &.buttons-container {
          display: flex;
          justify-content: space-evenly;
        }
      }

      .button {
        &.proposals-accounts-select {
          &:focus {
            outline-color: $brand-dark;
          }
          font-size: medium;
          padding-top: 0;
          padding-bottom: 0;
        }
        &.actions-button {
          width: 15%;
          &:hover {
            &.is-danger {
              background-color: $brand-light;
              color: $grey-dark;
            }
            &.is-brand-dark {
              background-color: $brand-light;
              border-color: $brand-light;
            }
          }
        }
      }

      // a span with text
      .account-list-dropdown-label {
        width: 10rem;
      }

      .accounts-dropdown-menu {
        width: 100%;
      }
    }
  }
}
