@import '../../../../styles/common.scss';

.proposals-list-main-container {
  padding: 1rem;

  .proposals-dropdown-label {
    width: 9rem;
  }

  .account-list-dropdown-label {
    width: 9rem;
  }

  .actions-column {
    .dropdown-trigger {
      button.button {
        background-color: transparent;
        &:hover {
          background-color: $brand-dark;
          color: $white-light;
        }
      }
    }

    .dropdown-content {
      &.proposals-actions {
        margin-left: -90%;
        margin-top: -15%;
        width: 12rem;
        .dropdown-item {
          padding: 0;
          ul.menu-list {
            a.proposals-action {
              &:hover {
                background-color: $brand-dark;
                color: $white-light;
              }
            }
          }
        }
      }
    }
  }

  .proposals-left-title-part-container {
    width: 25rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .proposals-table-title-container {
    margin-bottom: 1rem;

    display: flex;
    flex-direction: row;
    justify-content: space-between;

    padding-right: 1rem;

    .create-a-proposal-container {
      justify-content: center;
      align-items: center;
      display: flex;
      height: 2.5rem;

      .create-a-proposal-icon {
        height: 3.4rem;
        width: 1.6rem;
        font-size: 1.9rem;

        &:hover {
          cursor: pointer;
          font-size: 2.3rem;
        }
      }
    }
  }
}

:export {
  brandLight: $brand-light;
  brandDark: $brand-dark;
}
